// eslint-disable-next-line no-unused-vars
import React from 'react'

export const LANGUAGES = ['uk', 'ru']

export const BLOG_TOKENS = {
  uk: {
    TITLE: '',
    NEW: 'new',
    POPULAR: 'popular',
    ALL: 'all',
    TYPES: 'types',
    READ: 'read'
  },
  ru: {
    TITLE: '',
    NEW: 'новое',
    POPULAR: 'популярное',
    ALL: 'Все',
    TYPES: 'типы',
    READ: 'читать'
  }
}

export const HEADER_TOKENS = {
  uk: [
    {
      id: '_home',
      title: 'Головна',
      path: '/',
      tabIndex: 0,
      type: 'siteLink'
    },
    {
      id: '_rules',
      title: 'Фінансові послуги',
      path: '/rules',
      tabIndex: 1,
      type: 'siteLink',
      dropDownOptions: [
        { title: 'Діяльність з обміну валют', path: '/rules/exchange' },
        { title: 'Кредитування', path: '/rules/credit' },
        { title: 'Факторинг', path: '/rules/factoring' },
        { title: 'Лізинг', path: '/rules/leasing' }
      ]
    },
    {
      id: '_department',
      title: 'Відділення',
      path: '/department',
      tabIndex: 2,
      type: 'siteLink'
    },
    {
      id: '_protection',
      title: 'Захист прав споживачiв',
      path: '/protection',
      tabIndex: 3,
      type: 'siteLink'
    },
    {
      id: '_aboutus',
      title: 'Про компанію',
      path: '/about-us',
      tabIndex: 4,
      type: 'siteLink',
      dropDownOptions: [
        { title: 'Про компанію', path: '/about-us' },
        { title: 'Розміщення інформації', path: '/about-us/info' }
      ]
    },
    {
      id: '_contacts',
      title: 'Контакти',
      path: '/contacts',
      tabIndex: 5,
      type: 'siteLink'
    }
  ],
  ru: [
    {
      id: '_home',
      title: 'Главная',
      path: '/',
      tabIndex: 0,
      type: 'siteLink'
    },
    {
      id: '_rules',
      title: 'Финансовые услуги',
      path: '/rules',
      tabIndex: 1,
      type: 'siteLink',
      dropDownOptions: [
        { title: 'Деятельность по обмену валют', path: '/rules/exchange' },
        { title: 'Кредитование', path: '/rules/credit' },
        { title: 'Факторинг', path: '/rules/factoring' },
        { title: 'Лизинг', path: '/rules/leasing' }
      ]
    },
    {
      id: '_department',
      title: 'Отделения',
      path: '/department',
      tabIndex: 2,
      type: 'siteLink'
    },
    {
      id: '_protection',
      title: 'Защита потребителя',
      path: '/protection',
      tabIndex: 3,
      type: 'siteLink'
    },
    {
      id: '_aboutus',
      title: 'О компании',
      path: '/about-us',
      tabIndex: 4,
      type: 'siteLink',
      dropDownOptions: [
        { title: 'О компании', path: '/about-us' },
        { title: 'Размещение информации', path: '/about-us/info' }
      ]
    },
    {
      id: '_contacts',
      title: 'Контакты',
      path: '/contacts',
      tabIndex: 5,
      type: 'siteLink'
    }
  ]
}

export const HEADER_LANGS = {
  uk: 'UK',
  ru: 'RU'
}

export const FOOTER_INFO = {
  timetable: {
    uk: {
      title: 'Графік роботи:',
      description: [
        'Понеділок – П’ятниця з 9:00 до 18:00',
        'Обідня перерва з 13:00 до 14:00',
        'Субота, Неділя – вихідні дні'
      ]
    },
    ru: {
      title: 'График работы:',
      description: [
        'Понедельник - пятница с 9:00 до 18:00',
        'Обеденный перерыв с 13:00 до 14:00',
        'Суббота, воскресенье - выходные дни'
      ]
    }
  },
  contacts: {
    uk: [
      'Адреса: 01033, м. Київ, вул. Жилянська 30/32, офіс 204',
      'Телефон: +380979063721',
      'E-mail: urgent@moneyrepublic.com.ua - для роботи з держорганами',
      'public@moneyrepublic.com.ua - для публічних та комерційних звернень'
    ],
    ru: [
      'Адрес: 01033, г. Киев, ул. Жилянская, дом 30/32, офис 20',
      'Телефон: +380979063721',
      'E-mail: urgent@moneyrepublic.com.ua - для работы с госорганами',
      'public@moneyrepublic.com.ua - для публичных и коммерческих обращений'
    ]
  }
}

export const CONTACT_FORM_FIELDS = {
  uk: {
    name: "Ваше ім'я",
    email: 'E-mail',
    phoneCode: 'Код',
    phone: 'Номер телефону',
    skype: 'Skype',
    message: 'Коментар',
    btnTxt: 'Відправити',
    messageConfirm: 'Коментар відправлений',
    btnConfirmTxt: 'Готово'
  },
  ru: {
    name: 'Ваше имя',
    email: 'E-mail',
    phoneCode: 'Код',
    phone: 'Номер телефона',
    skype: 'Skype',
    message: 'Комментарий',
    btnTxt: 'Отправить',
    messageConfirm: 'Комментарий отправлен',
    btnConfirmTxt: 'Готово'
  }
}
