// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-ru-blog-jsx": () => import("./../../../src/pages/ru/blog.jsx" /* webpackChunkName: "component---src-pages-ru-blog-jsx" */),
  "component---src-pages-ru-index-jsx": () => import("./../../../src/pages/ru/index.jsx" /* webpackChunkName: "component---src-pages-ru-index-jsx" */),
  "component---src-pages-uk-blog-jsx": () => import("./../../../src/pages/uk/blog.jsx" /* webpackChunkName: "component---src-pages-uk-blog-jsx" */),
  "component---src-pages-uk-index-jsx": () => import("./../../../src/pages/uk/index.jsx" /* webpackChunkName: "component---src-pages-uk-index-jsx" */),
  "component---src-templates-blog-category-jsx": () => import("./../../../src/templates/blog-category.jsx" /* webpackChunkName: "component---src-templates-blog-category-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blog-post.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-default-jsx": () => import("./../../../src/templates/default.jsx" /* webpackChunkName: "component---src-templates-default-jsx" */),
  "component---src-templates-no-layout-jsx": () => import("./../../../src/templates/no-layout.jsx" /* webpackChunkName: "component---src-templates-no-layout-jsx" */)
}

