import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import {
  HEADER_TOKENS as MENU_LINKS,
  FOOTER_INFO
} from '../../constants/Languages'
import { resolvePath } from '../../utils'
import layoutContext from '../layoutContext'
import './Footer.scss'

const Footer = ({ cult }) => {
  const timetable = []
  const contacts = []
  const { langPrefix } = useContext(layoutContext)

  const menuLinks = MENU_LINKS[cult].map(({ id, title, path }) => {
    return (
      <Link key={id} className='footer-link' to={resolvePath(langPrefix, path)}>
        {title}
      </Link>
    )
  })

  for (let item of FOOTER_INFO.timetable[cult].description) {
    timetable.push(<p key={item}>{item}</p>)
  }

  for (let item of FOOTER_INFO.contacts[cult]) {
    contacts.push(<p key={item}>{item}</p>)
  }

  return (
    <footer className='footer-container'>
      <div className='footer-menu-links'>{menuLinks}</div>
      <div className='footer-description'>
        <div className='footer-info'>
          {FOOTER_INFO.timetable[cult].title}
          {timetable}
        </div>
        <div className='footer-info'>{contacts}</div>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  cult: PropTypes.string
}

export default Footer
