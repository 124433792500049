import PropTypes from 'prop-types'
import React from 'react'

import HeaderLinks from './HeaderLinks'

const HeaderMenu = ({ primaryLinks }) => (
  <div className='header-menu'>
    <HeaderLinks links={primaryLinks} />
  </div>
)

HeaderMenu.propTypes = {
  primaryLinks: PropTypes.array,
  languages: PropTypes.array
}

export default HeaderMenu
