import { HEADER_LANGS } from 'constants/Languages'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useState, useEffect, useCallback, useContext } from 'react'
import { CSSTransition } from 'react-transition-group'

import layoutContext from '../layoutContext'

const langToPath = {
  uk: '/uk',
  ru: '/ru'
}

const SelectLanguage = ({ languages }) => {
  const [isLanguageSelectOpen, languageSelectOpen] = useState(false)
  const { cult } = useContext(layoutContext)

  useEffect(() => {
    function closeHandler() {
      languageSelectOpen(false)
    }
    window.addEventListener('click', closeHandler)
    return () => window.removeEventListener('click', closeHandler)
  }, [])

  const setLangHandler = useCallback(
    language => {
      if (language === cult) {
        return navigate(window.location.pathname)
      }
      const path = window.location.pathname.replace(
        langToPath[cult],
        langToPath[language]
      )
      navigate(path)
    },
    [cult, navigate]
  )
  return (
    <div
      className={`language-selector${
        isLanguageSelectOpen ? ' select-open' : ''
      }`}
      onClick={e => {
        e.stopPropagation()
        languageSelectOpen(!isLanguageSelectOpen)
      }}
    >
      <div>{cult}</div>
      <CSSTransition
        classNames='transition-dropdown'
        in={isLanguageSelectOpen}
        unmountOnExit
        timeout={200}
      >
        <div className={'language-list language-list-open'}>
          {languages.map(language => (
            <div
              key={language}
              className='language-list-item'
              onClick={() => setLangHandler(language)}
            >
              {HEADER_LANGS[language]}
            </div>
          ))}
        </div>
      </CSSTransition>
    </div>
  )
}

SelectLanguage.propTypes = {
  languages: PropTypes.array
}

export default SelectLanguage
