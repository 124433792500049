/* eslint-disable no-undef */
import React, { useState } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { HEADER_TOKENS as HEADER_LINKS, LANGUAGES } from 'constants/Languages'

import LinkImage from '../Common/LinkImage/LinkImage'
import HeaderMenu from './HeaderMenu'
import SelectLanguage from './SelectLanguage'
import MobileMenu from './MobileMenu'
import Burger from './Burger'
import './Header.scss'

const Header = ({ cult }) => {
  const [isMenuOpen, menuOpen] = useState(false)

  function mobileMenuOpen() {
    menuOpen(!isMenuOpen)
    document.getElementsByTagName('body')[0].classList.toggle('fixed')
  }

  return (
    <StaticQuery
      query={graphql`
        query {
          logo: file(relativePath: { eq: "logo.svg" }) {
            publicURL
          }
        }
      `}
      render={data => {
        const primaryLinks = [...HEADER_LINKS[cult || 'uk']]

        return (
          <header className='header'>
            <div className='header-left-side'>
              <LinkImage
                src={data.logo.publicURL}
                alt='Money republic'
                href={'/' + cult}
                imgClassName='header-logo'
                linkClassName='header-logo-container'
                tabIndex={1}
              />
            </div>
            <div className='header-right-side'>
              <HeaderMenu primaryLinks={primaryLinks} cult={cult} />
              <SelectLanguage languages={LANGUAGES} />

              <MobileMenu
                primaryLinks={primaryLinks}
                isMenuOpen={isMenuOpen}
                menuOpen={mobileMenuOpen}
                cult={cult}
              />

              <Burger menuOpen={mobileMenuOpen} />
            </div>
          </header>
        )
      }}
    />
  )
}

Header.propTypes = {
  cult: PropTypes.string.isRequired
}

export default Header
